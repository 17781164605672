html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: #fff;
  font-family: Roboto, sans-serif;
  line-height: 1.4;
}
