// A Hassle-Free Way to Self-Host Google Fonts - https://google-webfonts-helper.herokuapp.com/fonts

@font-face {
  font-display: fallback;
  font-family: Rough;
  font-style: normal;
  font-weight: normal;
  src:  url('../fonts/Rough-Regular.woff') format('woff'),
        url('../fonts/Rough-Regular.ttf') format('ttf');
}


@font-face {
  font-display: fallback;
  font-family: Anatoma;
  font-style: normal;
  font-weight: normal;
  src:  url('../fonts/Anatoma-Book.woff') format('woff'),
}

@font-face {
  font-display: fallback;
  font-family: Brutal;
  font-style: normal;
  font-weight: normal;
  src:  url('../fonts/Brutal-Regular.woff') format('woff'),
        url('../fonts/Brutal-Regular.woff2') format('woff2'),
}

