// @import "~normalize.css/normalize.css";
@import "./base";
@import "./media";
@import "./fonts";



:root {
  --height: 0;
  --width: 0;
  
}

html {
  font-size: 62.5%;
}

html,
body {
  height: 100%;
  font-family: "Rough";
}
* {
  margin: 0;
  padding: 0;
}
img {
  width: 100%;
  height: 100%;
}
a {
  // text-decoration: none;
  color: black;
}
.board {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  &--reload {
    text-align: center;
    font-family: "Anatoma";
    margin-top: -5rem;
    font-size: 2.1rem;
    & .brutal-icon {
      height: 10rem;
    }
    & .again{
      display: block;
    }
    & .reload{
      display: block;
    }
    & .please{
      display: none;
    }
    &.wait{
      & .again{
        display: none;
      }
      & .reload{
        display: none;
      }
      & .please{
        display: block;
      }
    }
  }
}

.card {
  height: 90vmin;
  width: 90vmin;
  @include media-breakpoint-up(xl) {
    height: 70vmin;
    width: 70vmin;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  // border: solid 1px lightgrey;
  border-radius: 1%;
  box-shadow: 2px 2px 4px 0px rgba(190, 190, 190, 0.6);
  background-color: white;
  transform: translateX(-50%) translateY(-50%);
  animation: slide-up 0.3s cubic-bezier(0, 1.19, 0.44, 1.13);

  & canvas {
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
  }
  &::before {
    content: "";
    position: absolute;
    padding: 3rem;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(128, 128, 128, 0.1);
    opacity: 0;
    transform: scale(1.2);
    transition: all 0.3s;
  }
  &::after {
    font-family: "Brutal";
    content: "K";
    position: absolute;
    font-size: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0);
    // background: #bada55 url("../images/logo.png") no-repeat;
    // background-size: contain;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transform: scale(2) rotate(-90deg);
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s, color 0.3s;
  }
  &.like {
    & canvas {
      opacity: 0.1;
    }
    &::before {
      transform: scale(1);
      opacity: 1;
    }
    &::after {
      transform: scale(1) rotate(0deg);
      opacity: 1;
      color: rgb(57, 255, 20);
    }
  }
  &.dislike {
    & canvas {
      opacity: 0.1;
    }
    &::before {
      transform: scale(1);
      opacity: 1;
    }
    &::after {
      transform: scale(1) rotate(-180deg);
      opacity: 1;
      color: #ff0033;
    }
  }
}

.header {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: filter 0.3s;
  & .logo {
    height: 5rem;
    width: auto;
  }
  z-index: 999;
  &--about {
    font-family: "Brutal";
    font-size: 2.8rem;
    display: block;
    cursor: pointer;
  }
  &--close {
    cursor: pointer;
    margin: 1rem 0;
    height: 2.3rem;
    display: none;
    & img {
      width: auto;
    }
  }
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 2rem;
  font-family: "Anatoma", sans-serif;
  font-size: 1.8rem;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.modal {
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  height: calc(100% + 5rem);
  z-index: 2;
  transition: top .6s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  &__container{
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    color: white;
    z-index: 3;
    transition: top .6s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    padding: 30rem 2rem 0 2rem;
    &::after {
      background: linear-gradient(-45deg, #ffffff 25px, transparent 0), linear-gradient(45deg, #ffffff 25px, transparent 0);
      background-position: left-bottom;
      background-repeat: repeat-x;
      background-size: 50px 50px;
      content: " ";
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 50px;
    }
    &--share, &--about{
      display: block;
      align-items: center;
      text-align: center;
      margin: auto;
      height: 0;
      overflow:hidden;
      opacity: 0;
      transition: height .6s cubic-bezier(0.68, -0.6, 0.32, 1.6), opacity .6s;
      & svg{
        height: 100%;
        width: 100%;
      }
      & h1{
        font-size: 5vmin;
      }
      & .instagram-logo{
        filter: invert(1);
      }
      & p{
        font-family: 'Anatoma';
        font-size: 4vmin;
      }
    }
  }
  &__footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    opacity: 0;
    transition: opacity .6s ease-in-out;
    padding: 2rem;
    & img {
      height: auto;
      max-width: 40rem;
    }
  }
}

.blackground {
  & .header {
    filter: invert(1);
    &--close {
      display: block;
    }
    &--about {
      display: none;
    }
  }
  & .modal {
    top: -5rem;
    &.share{
      & .modal__container--share{
        opacity: 1;
        height: 80%;
      }
    }
    &.about{
      & .modal__container--about{
        opacity: 1;
        height: 80%;
      }
    }
    &__container{
      top: -15rem;
    }
    &__footer{
      opacity: 1;
    }
  }
}

.popup{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  &__container{
    position: relative;
    max-width: 80%;
    background: black;
    border: solid white 3px;
    color: white;
    padding: 3rem;
    &--socials{
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 3rem;
      & img{
        width: 4rem;
      }
    }
    &--exit{
      padding-top: 1rem;
      font-family: 'Anatoma';
      text-align: center;
      font-size: 1.6rem;
    }
  }
  &.hide{
    display: none;
  }
}

@keyframes slide-up {
  0% {
    transform: translateX(-50%) translateY(-50%) rotate(-20deg);
    margin-top: -50%;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
    margin-top: 0;
  }
}


#share-button{
  cursor: pointer;
  &:hover{
    fill: white;
    & text{
      fill: black;
    }
  }
}
#download-button{
  cursor: pointer;
  & path{
    animation: floating 1.2s infinite alternate;
  }
  &:hover{
    animation: shake 0.3s cubic-bezier(.36,.07,.19,.97) infinite alternate;
    & rect:not(.not-animate){
      animation: rainbow .3s infinite;
    }
    & path,text,.not-animate{
      fill: white;
    }
  }
}

@keyframes shake {
	0% { -webkit-transform: translate(2px, 1px) rotate(0deg); }
	10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); }
	20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); }
	30% { -webkit-transform: translate(0px, 2px) rotate(0deg); }
	40% { -webkit-transform: translate(1px, -1px) rotate(1deg); }
	50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); }
	60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); }
	70% { -webkit-transform: translate(2px, 1px) rotate(-1deg); }
	80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); }
	90% { -webkit-transform: translate(2px, 2px) rotate(0deg); }
	100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes floating {
  0% {
    transform: translateY(0rem) ;
  }
  100% {
    transform: translateY(2rem) ;
  }
}

@keyframes rainbow {
  0% {
    fill: #FF0000;
  }
  
  15% {
    fill: #FF7F00;
  }
  
  30% {
    fill: #FFFF00;
  }
  
  45% {
    fill: #00FF00;
  }
  
  60% {
    fill: #0000FF;
  }
  
  75% {
    fill: #4B0082;
  }
  100% {
    fill: #8F00FF;
  }
}

.btn{
  cursor: pointer;
  border: 0;
  padding: 0;
  background: none;
  line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}